import React from 'react';
import PropTypes from 'prop-types';
import {
  LightningBoltIcon,
  ClockIcon,
  BeakerIcon,
} from '@heroicons/react/outline';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import Code from '../../components/Code';
import Testimonial from '../../components/Testimonial';
import TechnicalSupport from '../../components/TechnicalSupport';
import CTAs from '../../components/CTAs';
import Seo from '../../components/SEO';

const features = [
  {
    name: 'Designed for Shopify',
    description:
      'Our Google Tag Manager data layer is designed specifically for Shopify and Shopify Plus stores.',
    icon: BeakerIcon,
  },
  {
    name: 'Set it up in minutes',
    description:
      ' You can install our Shopify app in 3 simple steps. On top of this, we have an easy onbording flow to help you complete the reqiured steps.',
    icon: ClockIcon,
  },
  {
    name: 'Lightning FAST',
    description:
      "It's built with performance in mind. Only what's needed for accurate tracking.",
    icon: LightningBoltIcon,
  },
];
const LandingPage = ({ data }) => (
  <Layout>
    <Seo
      title="Google Tag Manager Data Layer for Shopify"
      description="Simple 1-Click Google Analytics 4 (GA4) installation for Shopify, Google Tag Manager data layer, and 60+ ready-made marketing tags. Easy."
    />

    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            {/* <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Specification
            </span> */}
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Google Tag Manager Data Layer for Shopify
            </span>
          </h1>

          {/* <div className="inline-flex space-x-4">
            <span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-500 tracking-wide uppercase">
              Last update
            </span>
            <span className="inline-flex items-center text-sm font-medium text-indigo-500 space-x-1">
              <span>27 May 2021</span>
            </span>
          </div> */}

          <p className="mt-8 text-xl text-gray-500 leading-8">
            Building a Shopify store is hard, but setting up Google Tag Manager
            doesn't have to be. With{' '}
            <a
              className="text-indigo-600 hover:text-indigo-400"
              href="https://apps.shopify.com/google-tag-manager-installer?surface_type=website"
              target="_blank"
              rel="noreferrer noopener"
            >
              Easy Tag - GTM &amp; Data Layer
            </a>{' '}
            you can now install GTM and a data layer with a single click, and
            choose from our library of 60+ pre-built marketing tags. Setting up
            your marketing has never been so easy.
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2>Data Layer Features</h2>
          <div className="my-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <h2>Data Layer Events</h2>
          <h3>Product Impressions</h3>
          {/* <div>Available on pages</div>
          <div className="flex">
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
              Collection Page
            </span>
            <span className=" ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
              Search Results
            </span>
          </div> */}
          <p>
            The "impressions" event is pushed into the data layer when the user
            sees a collection page or a search results page.
          </p>
          <div className="text-sm">
            <Code
              code={`
{
  'ecommerce': {
    'currencyCode': 'EUR',
    'impressions': [
     {
       'name': 'Blue Silk Tuxedo',
       'id': '12345',
       'price': '15.25',
       'brand': 'Shopify',
       'category': 'Apparel',
       'variant': 'Gray',
       'list': 'Search Results',
       'position': 1,
       'productId': '5654568337561',
       'variantId': '36188836987033'
     }
    ]
  }
}
`}
              language="json"
            />
          </div>

          <h3>Product Clicks</h3>
          <p>
            The "productClick" event is pushed into the data layer when the user
            clicks on a product link in a collection page or a search results
            page.
          </p>
          <div className="text-sm">
            <Code
              code={`
{
  'event': 'productClick',
  'ecommerce': {
    'click': {
      'actionField': {
        'list': 'Search Results'
      },
      'products': [{
        'name': 'Blue Silk Tuxedo',
        'id': 12345,
        'price': '15.25',
        'brand': 'Shopify',
        'category': 'Apparel',
        'variant': 'Gray',
        'position': 1,
        'productId': '5654568337561',
        'variantId': '36188836987033'
      }]
    }
  }
}
`}
              language="json"
            />
          </div>

          <h3>Product Detail View</h3>
          <p>
            The "detail" event is pushed into the data layer when the user lands
            on a product page.
          </p>
          <div className="text-sm">
            <Code
              code={`
{
  'event': 'productDetailView',
  'ecommerce': {
    'detail': {
      'actionField': {
        'list': 'Apparel Gallery'
      },
      'products': [{
        'name': 'Blue Silk Tuxedo',
        'id': '12345',
        'price': '15.25',
        'brand': 'Shopify',
        'category': 'Apparel',
        'variant': 'Gray',
        'productId': '5654568337561',
        'variantId': '36188836987033'
      }]
    }
  }
}
`}
              language="json"
            />
          </div>

          <h3>Add to cart</h3>
          <p>
            The "addToCart" event is pushed into the data layer when the user
            adds a product into the cart.
          </p>
          <div className="text-sm">
            <Code
              code={`
{
  'event': 'addToCart',
  'ecommerce': {
    'currencyCode': 'EUR',
    'add': {
      'products': [{
        'name': 'Blue Silk Tuxedo',
        'id': '12345',
        'price': '15.25',
        'brand': 'Shopify',
        'category': 'Apparel',
        'variant': 'Gray',
        'quantity': 1,
        'productId': '5654568337561',
        'variantId': '36188836987033'
      }]
    }
  }
}
`}
              language="json"
            />
          </div>

          <h3>Remove from cart</h3>
          <p>
            The "removeFromCart" event is pushed into the data layer when the
            user removes a product from the cart.
          </p>
          <div className="text-sm">
            <Code
              code={`
{
  'event': 'removeFromCart',
  'ecommerce': {
    'remove': {
      'products': [{
          'name': 'Blue Silk Tuxedo',
          'id': '12345',
          'price': '15.25',
          'brand': 'Shopify',
          'category': 'Apparel',
          'variant': 'Gray',
          'quantity': 1,
          'productId': '5654568337561',
          'variantId': '36188836987033'
      }]
    }
  }
}
`}
              language="json"
            />
          </div>

          <h3>Checkout</h3>
          <p>
            The "checkout" event is pushed into the data layer when the user
            starts the checkout process.
          </p>
          <blockquote>
            <p>
              This event is available only if the store is on a Shopif Plus
              plan.
            </p>
          </blockquote>
          <div className="text-sm">
            <Code
              code={`
{
  'event': 'checkout',
  'ecommerce': {
    'checkout': {
      'actionField': {
        'step': 1
      },
      'products': [{
        'name': 'Blue Silk Tuxedo',
        'id': '12345',
        'price': '15.25',
        'brand': 'Shopify',
        'category': 'Apparel',
        'variant': 'Gray',
        'quantity': 1,
        'productId': '5654568337561',
        'variantId': '36188836987033'
      }]
    }
  },
}
`}
              language="json"
            />
          </div>

          <h3>Purchase</h3>
          <p>
            The purchase event is pushed into the data layer on when the user
            lands on the order "Thank You" page for the first time.
          </p>
          <div className="text-sm">
            <Code
              code={`
{
  'ecommerce': {
    'purchase': {
      'actionField': {
        'id': '12345',
        'affiliation': 'Online Store',
        'revenue': '35.43',
        'tax':'4.90',
        'shipping': '5.99',
        'coupon': 'SUMMER_SALE'
      },
      'products': [{
        'name': 'Blue Silk Tuxedo',
        'id': '12345',
        'price': '15.25',
        'brand': 'Shopify',
        'category': 'Apparel',
        'variant': 'Gray',
        'quantity': 1,
        'coupon': '',
        'productId': '5654568337561',
        'variantId': '36188836987033'
      }]
    }
  }
}
`}
              language="json"
            />
          </div>

          <h3>Customer</h3>
          <p>
            The "customer" event is pushed into the data layer on every page.
          </p>
          <div className="text-sm">
            <Code
              code={`
{
  'event': 'customer',
  customer: {
    type: 'loggedIn', // or 'guest'
    id: '123456',
    firstName: 'John',
    lastName: 'Doe',
    ordersCount: 1,
    customerTotalSpent: 63.12
  }
}
`}
              language="json"
            />
          </div>

          <h3>General</h3>
          <p>The "pageType" is availble in our data layer on every page.</p>
          <div className="text-sm">
            <Code
              code={`
{
  pageType: 'HomePage'
}
`}
              language="json"
            />
          </div>
        </div>
      </div>
    </div>
    <CTAs.SimpleJustified
      subtitle="Start using Easy Tag today."
      primaryActionLabel="Get Started"
    />
    <Testimonial
      name="Sam Franklin"
      logo={data.imgOriginalKettlebell}
      quote="Easy Tag saved me days! My data is clean, my tags are firing correctly, and I can focus on selling."
      photo={data.imgSamFranklin}
    />
    <TechnicalSupport image={data.imgBenefitFriendlyTechnicalSupport} />

    <CTAs.Big image={data.imgTagInstallHero} primaryActionLabel="Get Started" />
  </Layout>
);

LandingPage.propTypes = {
  data: PropTypes.shape({
    imgTagInstallHero: PropTypes.object.isRequired,
    imgOriginalKettlebell: PropTypes.object.isRequired,
    imgBenefitFriendlyTechnicalSupport: PropTypes.object.isRequired,
    imgSamFranklin: PropTypes.object.isRequired,
    imgEasyTagOverview: PropTypes.object.isRequired,
    imgGA4Events: PropTypes.object.isRequired,
  }).isRequired,
};

export default LandingPage;

export const data = graphql`
  query LandingPageDataLayerQuery {
    imgTagInstallHero: file(name: { eq: "taginstall-hero-image" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgShopifyGA4Insights: file(name: { eq: "shopify-ga4-insights" }) {
      name
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgGA4Events: file(name: { eq: "ga4-events" }) {
      name
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgOriginalKettlebell: file(name: { eq: "original-kettlebell" }) {
      name
      childImageSharp {
        fixed(width: 140, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    imgInstallEasyTag: file(name: { eq: "install-easy-tag-in-shopify" }) {
      name
      childImageSharp {
        fixed(width: 180, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    imgGA4Insights: file(name: { eq: "get-ga4-insights" }) {
      name
      childImageSharp {
        fixed(width: 180, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    imgImportGA4: file(name: { eq: "import-ga4-in-gtm" }) {
      name
      childImageSharp {
        fixed(width: 180, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    imgEasyTagOverview: file(name: { eq: "easy-tag-overview-in-shopify" }) {
      name
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgBenefitFriendlyTechnicalSupport: file(
      name: { eq: "benefit-friendly-technical-support" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgSamFranklin: file(name: { eq: "sam-franklin" }) {
      name
      childImageSharp {
        fixed(height: 40, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`;
